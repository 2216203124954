const config = {
  siteName: "x200 Labs",
  siteTitle: "x200 Labs Inc", // Site title.
  siteTitleShort: "x200", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "x200 Labs", // Alternative site title for SEO.
  siteLogo: "src/assets/img/logo-512.png", // Logo used for SEO and manifest. Relative to /
  siteUrl: "https://x200labs.com", // Domain of your website without pathPrefix.
  siteDescription: "x200 Labs", // Website description used for RSS feeds/meta description tag.
  siteAuthor: "x200 Labs", // Site author
  favIcon: "src/assets/img/favicon.ico",
  baseUrl: "https://x200labs.com",
  facebookUrl: "https://www.facebook.com/x200labs",
  twitterUrl: "https://twitter.com/x200labs",
  instagramUrl: "https://www.instagram.com/x200labs",
  linkedinUrl: "https://www.linkedin.com/in/x200labs",
  githubUrl: "https://github.com/x200labs",
  primaryColor: "#3E78BC",
  primaryBoxShadow: "0 12px 20px -10px rgba(62, 120, 188, 0.2), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(62, 120, 188, 0.2)",
  primaryBoxShadowHover: "0 14px 26px -12px rgba(62, 120, 188, 0.6), 0 4px 23px 0px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(62, 120, 188, 0.4)"
};

module.exports = config;
