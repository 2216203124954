import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Globe from "react-globe.gl";

// DEFAULTS
const country = 'United States';
const mapCenter = { lat: 13.6, lng: -90.5, altitude: 2 };
const backgroundColor = 'rgba(0,0,0,0)';

// DATA
import offices from './assets/data/offices.js';
import clients from './assets/data/clients.js';

import globeImageUrl from './assets/img/earth-blue-marble.jpg';

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const randomCityFromArray = (inputArray) => {
  var randomCity = inputArray[Math.floor(Math.random() * inputArray.length)];
  // console.log(randomCity);
  return randomCity;
}

var { startCity, startName, startLat,  startLng, endCity, endName, endLat, endLng } = '';

const randomCityPairFromArray = (startArray, endArray) => {
  startCity = randomCityFromArray(startArray);

  do {
    endCity = randomCityFromArray(endArray);
  } while (startCity == endCity);
};

const N1 = 100;

var arcsData = [...Array(N1).keys()].map(cityPair => {

  const container = {};

  const rndInt = randomIntFromInterval(1, 10)

  // Assign probabilities and arc properties
  // 50%
  if (rndInt >= 1 && rndInt <= 5) {
    randomCityPairFromArray(offices, clients);
    container.color = "#FFFFFF";
    container.arcDashLength = 0.05;
    container.arcDashGap = 0.1;
    container.arcDashAnimateTime = 2000;

  // 30%
  } else if( rndInt >= 6 && rndInt <= 8 ) {
    randomCityPairFromArray(offices, offices);
    container.color = "#FF0000";
    container.arcDashLength = 0.05;
    container.arcDashGap = 0.1;
    container.arcDashAnimateTime = 2000;

  // 20%
  } else if( rndInt >= 9 && rndInt <= 10 ) {
    randomCityPairFromArray(clients, offices);
    container.color = "#00FF94";
    container.arcDashLength = 0.05;
    container.arcDashGap = 0.1;
    container.arcDashAnimateTime = 2000;
  }

  container.startName = startCity['city'];
  container.startLat = startCity['lat'];
  container.startLng = startCity['lng'];
  container.endName = endCity['city'];
  container.endLat = endCity['lat'];
  container.endLng = endCity['lng'];

  return container;

});
// TEST OUTPUT
// console.log(arcsData);


// RANDOM DATA FOR TESTING
// const Nrand = 25;
// const arcsData = [...Array(Nrand).keys()].map(() => ({
//   startLat: (Math.random() - 0.5) * 180,
//   startLng: (Math.random() - 0.5) * 360,
//   endLat: (Math.random() - 0.5) * 180,
//   endLng: (Math.random() - 0.5) * 360,
//   color: [
//     ["#B43632", "#EEB649", "#CCD556"][Math.round(Math.random() * 3)],
//     ["#B43632", "#EEB649", "#CCD556"][Math.round(Math.random() * 3)]
//   ]
// }));

const GlobeScene = () => {

  const globeEl = useRef();
  const [size, setSize] = useState([0, 0]);

  const [cities, setCities] = useState([]);
  const [hoverArc, setHoverArc] = useState();

  // SCENE SIZING
  useLayoutEffect(() => {
    setSize([window.innerWidth, window.innerHeight * 1.5]);
    // Auto-rotate
    globeEl.current.controls().autoRotate = true;
    globeEl.current.controls().autoRotateSpeed = 0.4;
    // Controls
    globeEl.current.controls().enable = true;
    globeEl.current.controls().enableZoom = false;
  }, []);

  // INITIAL PERSPECTIVE
  useEffect(() => {
    // aim at continental US centroid
    globeEl.current.pointOfView(mapCenter, 4000);
  }, []);


  return (
    <div
      className="App"
      style={{
        background: "transparent",
        position: "relative",
        width: "100%",
      }}
    >
      <div
        style={{

        }}
      >
        <Globe
          ref={globeEl}
          width={size[0]}
          height={size[1]}
          waitForGlobeReady={false}
          enablePointerInteraction={true}
          globeImageUrl={globeImageUrl}
          backgroundColor={backgroundColor}

          arcsData={arcsData}
          arcColor={'color'}
          arcDashLength={'arcDashLength'}
          arcDashGap={'arcDashGap'}
          arcDashAnimateTime={'arcDashAnimateTime'}

        />
      </div>
    </div>
  );
}

export default GlobeScene;
