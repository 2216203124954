
import { useEffect, useRef } from "react";
import * as THREE from "three";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/ThreeDScene.js";

const useStyles = makeStyles(styles);

const CubeScene = () => {

  const classes = useStyles();

  const mountRef = useRef(null);

  useEffect(() => {

    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
    var renderer = new THREE.WebGLRenderer( { alpha: true } );

    renderer.setSize( (0.5 * window.innerWidth), (0.5 * window.innerHeight) );
    mountRef.current.appendChild( renderer.domElement );

    var geometry = new THREE.BoxGeometry( 3, 3, 3 );
    var material = new THREE.MeshBasicMaterial( { color: 0x3E78BC } );
    var cube = new THREE.Mesh( geometry, material );

    scene.add( cube );
    camera.position.z = 5;

    var animate = function () {
      requestAnimationFrame( animate );
      cube.rotation.x += 0.01;
      cube.rotation.y += 0.01;
      renderer.render( scene, camera );
    };

    let onWindowResize = function () {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize( window.innerWidth, window.innerHeight );
    };

    window.addEventListener("resize", onWindowResize, false);

    animate();

    return () => mountRef.current.removeChild( renderer.domElement);
  }, []);

  return (
    <div ref={mountRef}
      className={classes.info}
      style={{
        width: "100%",
        display: "grid",
        justifyContent: "center",
        paddingBottom: "2rem"
      }}
    >

    </div>
  );
}

export default CubeScene;
