import React, { useState } from "react";
import ReactDOM from "react-dom";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import logo from "assets/img/logo-512.png";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import "./assets/css/styles.css";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

import featuresList from './assets/data/features.js';
const initialState = featuresList;

const ProjectCalculator = () => {

  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  const [quoteTotal, setQuoteTotal] = useState(0);
  const [features, setFeatures] = useState(initialState);

  const toggleDisabled = (id) => {
    setFeatures(prevState => {
        // Loop over your list
        return prevState.map((item) => {
            // Check for the item with the specified id, add its cost to the quoteTotal, and toggle the disabled prop
            setQuoteTotal(quoteTotal + item.cost);
            return item.id === id ? {...item, disabled: !item.disabled} : item
        })
    })
  };

  const handleResult = () => {
    // sendResult();
    resetCalculator();
  }

  const resetCalculator = () => {
    setQuoteTotal(0);
    setFeatures(initialState);

  };

  const currencyFormat = (num) => {
     return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  var enabledFeatures = features.filter( obj => obj.disabled === true );

  return (
    <div className="calculator">

      <div className="quote">

        <GridContainer>
          <GridItem xs={4} sm={4} md={6}>
            <h2 className="quoteTitle">Estimate: </h2>
          </GridItem>
          <GridItem xs={8} sm={8} md={6}>
            <h2 className="quoteTotal">{currencyFormat(quoteTotal)} USD</h2>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <h2 className="featuresTitle">Features: </h2>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <ul className="featuresList">
              {enabledFeatures.map(obj => {
                return (
                  <li key={obj.id}>
                    <h2>{obj.name}</h2>
                  </li>
                );
              })}
            </ul>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <button className="resetCalculator" onClick={() => {
              resetCalculator();
            }}>Reset Quote</button>
          </GridItem>
        </GridContainer>

      </div>

      <section className="panel">
        <section className="numbers">

          {/* STEP 1 */}
          <h3>What kind of a project do you have?</h3>
          <button disabled={features[0].disabled} onClick={() => {
            toggleDisabled(1);
          }}>Mobile App</button>
          <button disabled={features[1].disabled} onClick={() => {
            toggleDisabled(2);
          }}>Web App</button>
          <button disabled={features[2].disabled} onClick={() => {
            toggleDisabled(3);
          }}>IoT</button>
          <button disabled={features[3].disabled} onClick={() => {
            toggleDisabled(4);
          }}>Wireless</button>

          {/* STEP 2 */}
          <h3>What kind of options do you need?</h3>
          <button disabled={features[4].disabled} onClick={() => {
            toggleDisabled(5);
          }}>Option 1-a</button>
          <button disabled={features[5].disabled} onClick={() => {
            toggleDisabled(6);
          }}>Option 1-b</button>

          {/* STEP 2 - CONDITIONAL RENDERING */}
          {features[4].disabled == true && features[5].disabled !== true &&
            <button disabled={features[6].disabled} onClick={() => {
              toggleDisabled(7);
            }}>Option 1-a-i</button>
          }{features[4].disabled == true && features[5].disabled !== true &&
            <button disabled={features[7].disabled} onClick={() => {
              toggleDisabled(8);
            }}>Option 1-a-ii</button>
          }

          {/* STEP 2 - CONDITIONAL RENDERING */}
          {features[5].disabled == true && features[4].disabled !== true &&
            <button disabled={features[8].disabled} onClick={() => {
              toggleDisabled(9);
            }}>Option 1-b-i</button>
          }{features[5].disabled == true && features[4].disabled !== true &&
            <button disabled={features[9].disabled} onClick={() => {
              toggleDisabled(10);
            }}>Option 1-b-ii</button>
          }

          {/* STEP 3 */}
          <h3>What kind of options do you need?</h3>
          <button disabled={features[10].disabled} onClick={() => {
            toggleDisabled(11);
          }}>Option 2-a</button>
          <button disabled={features[11].disabled} onClick={() => {
            toggleDisabled(12);
          }}>Option 2-b</button>
          <button disabled={features[12].disabled} onClick={() => {
            toggleDisabled(13);
          }}>Option 2-c</button>
          <button disabled={features[13].disabled} onClick={() => {
            toggleDisabled(14);
          }}>Option 2-d</button>

          {/* SUBMISSION */}
          <h3>Send my rough estimate to the team?</h3>
          <button type="submit" onClick={() => handleResult()}>Send It!</button>

        </section>
      </section>
    </div>
  );
}

export default ProjectCalculator;
