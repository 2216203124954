const clients = [
  {
    city: "Denver, CO",
    lat: 39.767381,
    lng: -104.979146
  },
  {
    city: "Boulder, CO",
    lat: 38.922024,
    lng: -105.253799,
  },
  {
    city: "Austin, TX",
    lat: 30.297018,
    lng: -97.740533
  },
  {
    city: "Dallas, TX",
    lat: 32.803436,
    lng: -96.790214
  },
  {
    city: "Georgetown, TX",
    lat: 30.657997,
    lng: -97.678735
  },
  {
    city: "Houston, TX",
    lat: 29.770338,
    lng: -95.373
  },
  {
    city: "Coleman, TX",
    lat: 31.844899,
    lng: -99.429703
  },
  {
    city: "Yukon, OK",
    lat: 35.534461,
    lng: -97.766627,
  },
  {
    city: "Henderson, NV",
    lat: 36.070192,
    lng: -115.008297,
  },
  {
    city: "Los Angeles, CA",
    lat: 34.070862,
    lng: -118.24791,
  },
  {
    city: "Atlanta, GA",
    lat: 33.77344,
    lng: -84.389399,
  },
  {
    city: "Sheridan, WY",
    lat: 44.826656,
    lng: -106.947073,
  },
  {
    city: "Big Sky, MT",
    lat: 45.298075,
    lng: -111.359457,
  },
  {
    city: "Grand Rapids, MI",
    lat: 38.922024,
    lng: -77.038172,
  },
  {
    city: "Washington DC",
    lat: 40.034975,
    lng: -77.038172,
  },
  {
    city: "Punta Gorda, Belize",
    lat: 16.121027,
    lng: -88.818264,
  },
];

export default clients;
