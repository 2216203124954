import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import { gsap } from "gsap";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

import logo from "assets/img/logo-512.png";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import "./assets/css/styles.css";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

// TEST FUNCTION
const triggerLogger = () => {
  console.log("FUNCTION TRIGGERED");
}

const ScrollPathStory = () => {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  const section3counter = useRef(null);
  const section4counter = useRef(null);

  useLayoutEffect(() => {

    // Section 1
    gsap.fromTo("#section-1", {
      autoAlpha: 0,
      ease: 'none',
      delay: 0,
    }, {
      autoAlpha: 1,
      ease: 'none',
      duration: 1,
      scrollTrigger: {
        trigger: "#section-1",
        start: "top bottom",
        end: "bottom bottom-=400px",
        scrub: true,
      }
    });

    // Section Triggers for Section 1
    ScrollTrigger.create({
      trigger: "#section-1",
      start: "top top-=-200px",
      endTrigger: '#section-2',
      end: "top top",
      onEnter: triggerLogger,
      onLeave: triggerLogger,
      onEnterBack: triggerLogger,
      onLeaveBack: triggerLogger
    });

    // Section 2
    gsap.fromTo("#section-2", {
      autoAlpha: 0,
      ease: 'none',
      delay: 0,
      xPercent: -100,
    }, {
      autoAlpha: 1,
      ease: 'none',
      duration: 1,
      xPercent: 0,
      opacity: 0,
      scrollTrigger: {
        trigger: "#section-2",
        start: "top bottom",
        end: "top top+=400px",
        scrub: true,
      }
    });

    // Section 3
    gsap.fromTo("#section-3", {
      autoAlpha: 0,
      ease: 'none',
      delay: 0,
      xPercent: 100,
    }, {
      autoAlpha: 1,
      ease: 'none',
      duration: 1,
      xPercent: 0,
      opacity: 0,
      scrollTrigger: {
        trigger: "#section-3",
        start: "top bottom",
        end: "top top+=400px",
        scrub: true,
      }
    });

    // Custom trigger for Section 3
    ScrollTrigger.create({
      trigger: "#section-3",
      start: "top bottom+=-200px",
      endTrigger: '#section-3',
      end: "bottom top",
      onUpdate: (self) => {
        const progress3 = Math.max(2, Math.ceil(self.progress * 100)); //No lower than 2.
        section3counter.current.innerHTML = progress3;
        // console.log(
        //   "progress:",
        //   self.progress.toFixed(3),
        //   "direction:",
        //   self.direction,
        //   "velocity",
        //   self.getVelocity()
        // );
      },
    });
    ScrollTrigger.refresh();

    // Section 4
    gsap.fromTo("#section-4", {
      autoAlpha: 0,
      ease: 'none',
      delay: 0,
    }, {
      autoAlpha: 1,
      ease: 'none',
      duration: 1,
      opacity: 0,
      scrollTrigger: {
        trigger: "#section-4",
        start: "top bottom+=-200px",
        end: "bottom top-=-400px",
        scrub: true,
      }
    });

    // Custom trigger for Section 4
    var action = gsap.to('#section-4', {
      backgroundColor: 'white',
      border: '1px solid black',
      color: 'black',
      ease: 'none',
      duration: 0.25,
      zIndex: '2000'
    });

    ScrollTrigger.create({
      trigger: "#section-4",
      start: "top top+=100px",
      endTrigger: '#section-8',
      end: "top bottom",
      pin: true,
      pinSpacing: false,
      animation: action,
      toggleActions: 'play reverse play reverse'
    });
    ScrollTrigger.refresh();

  }, []);

  // SCROLL SVG PATH DRAW
  useLayoutEffect(() => {

    window.addEventListener("scroll", scrollEventHandler);
    scrollEventHandler();

    function scrollEventHandler() {

      // DEFINE WHEN SVG DRAWING SHOULD START AND STOP BASED ON PERCENTAGE OF WHOLE PAGE SCROLLED
      var scrollBehavior = [
         {id: 'x200-etched', startPct: 3, endPct: 6},
         {id: 'animated-signal-emitting-1', startPct: 6, endPct:  8},

         {id: 'connector-1', startPct: 9, endPct: 10},
         {id: 'connector-2', startPct: 17, endPct: 18},
         {id: 'connector-3', startPct: 21, endPct: 22},
         {id: 'connector-4', startPct: 27, endPct: 28},
         {id: 'connector-5', startPct: 31, endPct: 32},
         {id: 'connector-6', startPct: 37, endPct: 38},
         {id: 'connector-7', startPct: 42, endPct: 43},
         {id: 'connector-8', startPct: 53, endPct: 54},
         {id: 'connector-9', startPct: 62, endPct: 63},
         {id: 'connector-10', startPct: 70, endPct: 71},
         {id: 'connector-11', startPct: 78, endPct: 79},
         {id: 'connector-12', startPct: 84, endPct: 85},
         {id: 'connector-13', startPct: 90, endPct: 91},

         {id: 'footer-fork', startPct: 95, endPct: 100},
         {id: 'animated-signal-receiving-footer', startPct: 96, endPct: 100},
      ];

      // Calculate how far down the page the user has scrolled
      var percentOfScroll = 100 * (document.body.scrollTop + document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);

      console.log("% scrolled down page: ", percentOfScroll);

      // For each element that is drawn...
      for (var i = 0; i < scrollBehavior.length; i++)
      {
        var data = scrollBehavior[i];
        var elem = document.getElementById(data.id);

        // Get the length of this elements path
        var dashLen = calcPathLength(elem);

        // Calculate where the current scroll position falls relative to our path
        var fractionThroughThisElem = (percentOfScroll - data.startPct) / (data.endPct - data.startPct);
        // Clamp the fraction value to within this path (0 .. 1)
        fractionThroughThisElem = Math.max(fractionThroughThisElem, 0);
        fractionThroughThisElem = Math.min(fractionThroughThisElem, 1);

        var dashOffset = dashLen * (1 - fractionThroughThisElem);

        elem.style.strokeDasharray = dashLen;
        elem.style.strokeDashoffset = dashOffset;

        // console.log(elem);
      }
    };

    function calcPathLength(elem)
    {
      // Path
      if (elem.getTotalLength)
      {
        return elem.getTotalLength();
      }
      // Rectangle
      else if (elem.tagName === "rect")
      {
        // Perimeter length = w + w + h + h
        return (elem.width.baseVal.value + elem.height.baseVal.value) * 2;
      }
      // Circle
      else if (elem.tagName === "circle")
      {
        // Perimeter length = 2 * r * PI
        return elem.r.baseVal.value * 2 * Math.PI;
      }
      // Line
      else if (elem.tagName === "line")
      {
        // Length^2 = a^2 + b^2     // Pythagorean Theorem
        var dx = elem.x2.baseVal.value - elem.x1.baseVal.value;
        var dy = elem.y2.baseVal.value - elem.y1.baseVal.value;
        return Math.sqrt(dx * dx + dy * dy);
      }
    }

  }, []);

  return (
    <>

    <main role="article" title="line" className="scrollytell">
        <div className="scrollytell-wrapper">

          {/*<h2 className={classes.title} style={{color: "red"}}>Header Container</h2>*/}

          <div className="header-container">

            <img src={logo} alt="..."
              className={imageClasses}
              width={"150px"}
              height={"150px"}
              style={{backgroundColor: "black", borderRadius: "50%"}}
            />
            <h5 className={classes.description}>
              We connect the circuits,<br/>We wrangle up the algorithms,<br/>We help solve the important problems.
            </h5>

            <span class="scroll-btn">
              <a href="#">
                <span class="mouse">
                  <span>
                  </span>
                </span>
              </a>
              <p>scroll to learn more</p>
            </span>

          </div>

          <div className="hero-container">

          {/*<h2 className={classes.title} style={{color: "red"}}>Hero Container</h2>*/}

              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="850 -100 1280 900" style={{
                enableBackground: "new 0 0 1280 800"
              }}>
                <g className="all">

                  <path id="x200-etched" className="st0" d="M 1008 96 L 1104 240 L 1008 384 L 1104 384 L 1152 312 L 1200 384 L 1224 384 C 1212.936 331.056 1203.072 326.088 1239.168 293.904 L 1200 240 L 1272 144 L 1368 144 C 1392 144 1440.456 156.456 1392 192 L 1272 288 C 1242.48 308.232 1224 336 1248 384 L 1488 384 L 1488 360 L 1560 360 C 1584 384 1608 384 1632 384 C 1704 384 1752 336 1752 216 L 1776 216 C 1776 336 1824 384 1896 384 C 1968 384 2016 336 2016 216 C 2016 120 1968 48 1896 48 C 1824 48 1776 120 1776 192 M 1848 216 C 1848 312 1872 336 1896 336 C 1920 336 1944 312 1944 216 C 1944 120 1920 96 1896 96 C 1872 96 1848 120 1848 216 M 1776 192 L 1752 192 C 1752 120 1704 48 1632 48 C 1560 48 1512 96 1512 216 C 1512 312 1536 336 1536 336 L 1512 336 M 1584 216 C 1584 312 1608 336 1632 336 C 1656 336 1680 312 1680 216 C 1680 120 1656 96 1632 96 C 1608 96 1584 120 1584 216 M 1512 336 L 1488 336 L 1488 312 L 1344 312 C 1368 288 1440 240 1488 192 C 1512 144 1488 72 1416 72 L 1224 72 L 1152 168 L 1080 72 L 960 72 L 960 384 C 960 420 984 444 1008 444 L 1500 444 L 1992 444 C 2028 444 2028 480 1992 480 L 1500 480 L 1500 516 A 12 12 90 0 0 1500 576 A 12 12 90 0 0 1500 516"/>

                  <path id="animated-signal-emitting-1" className="st0" d="M 1008 96 M 1414 539 A 1 1 0 0 0 1589 539 M 1342 538 A 1 1 0 0 0 1657 538 M 1279 537 A 1 1 0 0 0 1720 540"/>

                </g>
              </svg>

          </div>

          {/*<h2 className={classes.title} style={{color: "red"}}>Content Container</h2>*/}

          <div className="content-container">

            <section id="section-1">
              <div className="section-child">
                <h2>Step #1</h2>
                <h3>Plan</h3>
              </div>
            </section>

            <div className="connector">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="420 0 1200 780" style={{
                enableBackground: "new 0 0 1280 800"
              }}>
                <g className="all">

                  <path id="connector-1" className="st0" d="M 1034 80 A 1 1 0 0 0 1034 20 A 1 1 0 0 0 1034 80 V 717 A 1 1 0 0 0 1036 776 A 1 1 0 0 0 1036 717"/>

                </g>
              </svg>
            </div>

            <section id="section-2">
              <div className="section-child">
                <GridContainer justify="center">
                  <GridItem xs={12} sm={6} md={6}>

                    <div className="img-wrapper">
                      <img src={logo} alt="..."
                        className={imageClasses}
                        style={{backgroundColor: "black", borderRadius: "5%"}}
                      />
                    </div>

                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <h3>Slide in from left</h3>
                    <ul>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Urna condimentum mattis pellentesque id</li>
                      <li>Ipsum dolor sit amet consectetur adipiscing elit</li>
                    </ul>
                  </GridItem>
                </GridContainer>
              </div>
            </section>

            <div className="connector">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="420 0 1200 780" style={{
                enableBackground: "new 0 0 1280 800"
              }}>
                <g className="all">

                  <path id="connector-2" className="st0" d="M 1034 80 A 1 1 0 0 0 1034 20 A 1 1 0 0 0 1034 80 V 717 A 1 1 0 0 0 1036 776 A 1 1 0 0 0 1036 717"/>

                </g>
              </svg>
            </div>

            <section id="section-3">
              <div className="section-child">
                <h3>Step #<span ref={section3counter}>2</span>: Execute.</h3>
                <p>Slide in from right</p>
              </div>
            </section>

            <div className="connector">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="420 0 1200 780" style={{
                enableBackground: "new 0 0 1280 800"
              }}>
                <g className="all">

                  <path id="connector-3" className="st0" d="M 1034 80 A 1 1 0 0 0 1034 20 A 1 1 0 0 0 1034 80 V 717 A 1 1 0 0 0 1036 776 A 1 1 0 0 0 1036 717"/>

                </g>
              </svg>
            </div>

            <section id="section-4" className="sticky">
              <div className="section-child">
                <h3>This is a sticky section.</h3>
                <ul>
                  <li>Sticky @ 100px below top</li>
                  <li>Change background to white</li>
                  <li>Change border to black</li>
                  <li>Unstick when #section-10 scrolls into view</li>
                </ul>
              </div>
            </section>

            <div className="connector">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="420 0 1200 780" style={{
                enableBackground: "new 0 0 1280 800"
              }}>
                <g className="all">

                  <path id="connector-4" className="st0" d="M 1034 80 A 1 1 0 0 0 1034 20 A 1 1 0 0 0 1034 80 V 717 A 1 1 0 0 0 1036 776 A 1 1 0 0 0 1036 717"/>

                </g>
              </svg>
            </div>

            <section id="section-5">
              <div className="section-child">
                <h3>Heading</h3>
                <ul>
                  <li>Lorem ipsum dolor sit amet</li>
                  <li>Urna condimentum mattis pellentesque id</li>
                  <li>Ipsum dolor sit amet consectetur adipiscing elit</li>
                </ul>
              </div>
            </section>

            <div className="connector">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="420 0 1200 800" style={{
                enableBackground: "new 0 0 1280 800"
              }}>
                <g className="all">

                  <path id="connector-5" className="st0" d="M 1034 80 A 1 1 0 0 0 1034 20 A 1 1 0 0 0 1034 80 V 717 A 1 1 0 0 0 1036 776 A 1 1 0 0 0 1036 717"/>

                </g>
              </svg>
            </div>

            <section id="section-6">
              <div className="section-child">
                <h3>Heading</h3>
                <ul>
                  <li>Lorem ipsum dolor sit amet</li>
                  <li>Urna condimentum mattis pellentesque id</li>
                  <li>Ipsum dolor sit amet consectetur adipiscing elit</li>
                </ul>
              </div>
            </section>

            <div className="connector">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="420 0 1200 780" style={{
                enableBackground: "new 0 0 1280 800"
              }}>
                <g className="all">

                  <path id="connector-6" className="st0" d="M 1034 80 A 1 1 0 0 0 1034 20 A 1 1 0 0 0 1034 80 V 717 A 1 1 0 0 0 1036 776 A 1 1 0 0 0 1036 717"/>

                </g>
              </svg>
            </div>

            <section id="section-7">
              <div className="section-child">
                <h3>Heading</h3>
                <ul>
                  <li>Lorem ipsum dolor sit amet</li>
                  <li>Urna condimentum mattis pellentesque id</li>
                  <li>Ipsum dolor sit amet consectetur adipiscing elit</li>
                </ul>
              </div>
            </section>

            <div className="connector">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="420 0 1200 780" style={{
                enableBackground: "new 0 0 1280 800"
              }}>
                <g className="all">

                  <path id="connector-7" className="st0" d="M 1034 80 A 1 1 0 0 0 1034 20 A 1 1 0 0 0 1034 80 V 717 A 1 1 0 0 0 1036 776 A 1 1 0 0 0 1036 717"/>

                </g>
              </svg>
            </div>

            <section id="section-filler">
              <div className="section-child">
                Here's a picture of a cat<br/>
                <div className="img-wrapper">
                  <img src={'http://placekitten.com/200/300'} alt="..."
                    className={imageClasses}
                    width={"200px"}
                    height={"300px"}
                    style={{backgroundColor: "black", borderRadius: "5%"}}
                  />
                </div>
              </div>
            </section>

            <div className="connector">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="420 0 1200 780" style={{
                enableBackground: "new 0 0 1280 800"
              }}>
                <g className="all">

                  <path id="connector-8" className="st0" d="M 1034 80 A 1 1 0 0 0 1034 20 A 1 1 0 0 0 1034 80 V 717 A 1 1 0 0 0 1036 776 A 1 1 0 0 0 1036 717"/>

                </g>
              </svg>
            </div>

            <section id="section-filler">
              <div className="section-child">
                Here's a picture of a cat<br/>
                <div className="img-wrapper">
                  <img src={'http://placekitten.com/200/300'} alt="..."
                    className={imageClasses}
                    width={"200px"}
                    height={"300px"}
                    style={{backgroundColor: "black", borderRadius: "5%"}}
                  />
                </div>
              </div>
            </section>

            <div className="connector">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="420 0 1200 780" style={{
                enableBackground: "new 0 0 1280 800"
              }}>
                <g className="all">

                  <path id="connector-9" className="st0" d="M 1034 80 A 1 1 0 0 0 1034 20 A 1 1 0 0 0 1034 80 V 717 A 1 1 0 0 0 1036 776 A 1 1 0 0 0 1036 717"/>

                </g>
              </svg>
            </div>

            <section id="section-filler">
              <div className="section-child">
                Here's a picture of a cat<br/>
                <div className="img-wrapper">
                  <img src={'http://placekitten.com/200/300'} alt="..."
                    className={imageClasses}
                    width={"200px"}
                    height={"300px"}
                    style={{backgroundColor: "black", borderRadius: "5%"}}
                  />
                </div>
              </div>
            </section>

            <div className="connector">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="420 0 1200 780" style={{
                enableBackground: "new 0 0 1280 800"
              }}>
                <g className="all">

                  <path id="connector-10" className="st0" d="M 1034 80 A 1 1 0 0 0 1034 20 A 1 1 0 0 0 1034 80 V 717 A 1 1 0 0 0 1036 776 A 1 1 0 0 0 1036 717"/>

                </g>
              </svg>
            </div>

            <section id="section-filler">
              <div className="section-child">
                Here's a picture of a cat<br/>
                <div className="img-wrapper">
                  <img src={'http://placekitten.com/200/300'} alt="..."
                    className={imageClasses}
                    width={"200px"}
                    height={"300px"}
                    style={{backgroundColor: "black", borderRadius: "5%"}}
                  />
                </div>
              </div>
            </section>

            <div className="connector">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="420 0 1200 780" style={{
                enableBackground: "new 0 0 1280 800"
              }}>
                <g className="all">

                  <path id="connector-11" className="st0" d="M 1034 80 A 1 1 0 0 0 1034 20 A 1 1 0 0 0 1034 80 V 717 A 1 1 0 0 0 1036 776 A 1 1 0 0 0 1036 717"/>

                </g>
              </svg>
            </div>

            <section id="section-8">
              <div className="section-child">
                <h3>Heading</h3>
                <ul>
                  <li>Lorem ipsum dolor sit amet</li>
                  <li>Urna condimentum mattis pellentesque id</li>
                  <li>Ipsum dolor sit amet consectetur adipiscing elit</li>
                </ul>
              </div>
            </section>

            <div className="connector">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="420 0 1200 780" style={{
                enableBackground: "new 0 0 1280 800"
              }}>
                <g className="all">

                  <path id="connector-12" className="st0" d="M 1034 80 A 1 1 0 0 0 1034 20 A 1 1 0 0 0 1034 80 V 717 A 1 1 0 0 0 1036 776 A 1 1 0 0 0 1036 717"/>

                </g>
              </svg>
            </div>

            <section id="section-9">
              <div className="section-child">
                <h3>Heading</h3>
                <ul>
                  <li>Lorem ipsum dolor sit amet</li>
                  <li>Urna condimentum mattis pellentesque id</li>
                  <li>Ipsum dolor sit amet consectetur adipiscing elit</li>
                </ul>
              </div>
            </section>

            <div className="connector">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="420 0 1200 780" style={{
                enableBackground: "new 0 0 1280 800"
              }}>
                <g className="all">

                  <path id="connector-13" className="st0" d="M 1034 80 A 1 1 0 0 0 1034 20 A 1 1 0 0 0 1034 80 V 717 A 1 1 0 0 0 1036 776 A 1 1 0 0 0 1036 717"/>

                </g>
              </svg>
            </div>

            <section id="section-10">
              <div className="section-child">
                <h3>Heading</h3>
                <ul>
                  <li>Lorem ipsum dolor sit amet</li>
                  <li>Urna condimentum mattis pellentesque id</li>
                  <li>Ipsum dolor sit amet consectetur adipiscing elit</li>
                </ul>
              </div>
            </section>

          </div>

          {/*<h2 className={classes.title} style={{color: "red"}}>Footer Container</h2>*/}

          <div className="footer-container">

            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="850 -100 1280 800" style={{
              enableBackground: "new 0 0 1280 800"
            }}>
              <g className="all">

                <path id="animated-signal-receiving-footer" className="st0" d="M 1568 230 A 1 1 0 0 0 1410 228 M 1648 232 A 1 1 0 0 0 1326 229 M 1726 232 A 1 1 0 0 0 1260 233"/>

                <path id="footer-fork" className="st0" d="M 1489 262 A 1 1 0 0 0 1490 205 A 1 1 0 0 0 1489 262 L 1490 486 L 1832 486 L 1831 650 A 1 1 0 0 0 1831 670 A 1 1 0 0 0 1831 650 M 1490 486 L 1161 485 L 1162 647 A 1 1 0 0 0 1162 668 A 1 1 0 0 0 1162 647"/>

              </g>
            </svg>

            <GridContainer justify="center">
              <GridItem xs={6} sm={6} md={6}>
                <Button
                  color="primary"
                  size="lg"
                  href="/contact-us"
                  target="_self"
                  rel="noopener noreferrer"
                  className="mobile-fit"
                >
                  <i className="fas fa-envelope" />
                  Send us a Message
                </Button>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <Button
                  color="primary"
                  size="lg"
                  href="/project-estimator"
                  target="_self"
                  rel="noopener noreferrer"
                  className="mobile-fit"
                >
                  <i className="fas fa-calculator" />
                  Try our Project Estimator
                </Button>
              </GridItem>
            </GridContainer>

          </div>

        </div>
    </main>
  </>
  );
};

export default ScrollPathStory;
