import React from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import CubeScene from "components/ThreeD/Cube.js";
import GlobeScene from "components/ThreeD/Globe.js";

import styles from "assets/jss/material-kit-react/views/theme.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import PartnerCarouselSection from "./Sections/PartnerCarouselSection.js";

const config = require("data/siteConfig");

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>{config.siteName} - Home</title>
      </Helmet>

      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={config.siteName}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/background.jpeg").default}
        style={{
          overflow: "hidden",
          height: "90vh"
        }}
      >
        <div className={'scene-wrapper'}
          style={{
            position: "absolute",
            top: "-40px",
            left: "0",
            zIndex: "100"
          }}
        >
          <GlobeScene />
        </div>
        <div className={'hero-wrapper'}
          style={{
            width: "100%",
            textAlign: "center",
            margin: "0px",
            padding: "20px",
            zIndex: "1786"
          }}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h1
                  className={classes.title}
                  style={{
                    textShadow: "1px 5px 11px #000000"
                  }}
                >
                  Hello World
                </h1>
                <h4
                  style={{
                    textShadow: "1px 5px 11px #000000"
                  }}
                >
                  Let's solve important problems together
                </h4>
                <br />
                <Button
                  color="primary"
                  size="lg"
                  href="/contact-us"
                  target="_self"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-envelope" />
                  Send us a Message
                </Button>

              </GridItem>
            </GridContainer>
          </div>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
          <PartnerCarouselSection />

        </div>
      </div>
      <Footer whiteFont />
    </div>
  );
}
