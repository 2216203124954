const offices = [
  {
    city: "Denver, CO",
    lat: 39.767381,
    lng: -104.979146
  },
  {
    city: "Austin, TX",
    lat: 30.297018,
    lng: -97.740533
  },
  {
    city: "Dallas, TX",
    lat: 32.803436,
    lng: -96.790214
  },
];

export default offices;
