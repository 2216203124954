const featuresList = [
  { id: 1, name: "Mobile App", cost: 1000, disabled: false },
  { id: 2, name: "Web App", cost: 1000, disabled: false },
  { id: 3, name: "IoT", cost: 1000, disabled: false },
  { id: 4, name: "Wireless", cost: 1000, disabled: false },
  { id: 5, name: "Option 1 a", cost: 1000, disabled: false },
  { id: 6, name: "Option 1 b", cost: 1000, disabled: false },
  { id: 7, name: "Option 1 a i", cost: 1000, disabled: false },
  { id: 8, name: "Option 1 a ii", cost: 1000, disabled: false },
  { id: 9, name: "Option 1 b i", cost: 1000, disabled: false },
  { id: 10, name: "Option 1 b ii", cost: 1000, disabled: false },
  { id: 11, name: "Option 2 a", cost: 1000, disabled: false },
  { id: 12, name: "Option 2 b", cost: 1000, disabled: false },
  { id: 13, name: "Option 2 c", cost: 1000, disabled: false },
  { id: 14, name: "Option 2 d", cost: 1000, disabled: false },
];

export default featuresList;
